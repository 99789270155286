import React, {
  useState,
  useContext,
  useRef,
  useCallback,
  useEffect,
} from "react";
import PropTypes from "prop-types";
import {
  Box,
  useMediaQuery,
  useTheme,
  Stepper,
  Step,
  StepLabel,
  Button,
  Snackbar,
  Tooltip,
  Typography,
  Tabs,
  Tab,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import { useAuth } from "../util/auth";
import { FormContext } from "./FormContext";
import PersonalInformation from "./FormSection1_PersonalInformation";
import WorkExperience from "./FormSection3_WorkExperience";
import Education from "./FormSection2_Education";
import TargetJobInformation, {
  formSchema,
} from "./FormSection5_TargetJobInformation";
import AdditionalQualifications from "./FormSection4_AdditionalQualifications";
import FormSectionHeader from "./FormSectionHeader";
import GenerateApplicationMaterialsButton from "./GenerateApplicationMaterialsButton";
import GeneratedContentDisplay from "./GeneratedContentDisplay";
import { db } from "../util/firebase";
import { doc, setDoc, onSnapshot } from "firebase/firestore";
import ErrorBoundary from "./ErrorBoundary";
import SidebarToggleIcon from "./SidebarToggleIcon";
import useSticky from "../hooks/useSticky";
import GenerationCreditsDisplay from "./GenerationCreditsDisplay";

const SCROLL_THRESHOLD = 150;
const STICKY_TAB_HEIGHT = 50;
const STICKY_TAB_HEIGHT_MOBILE = 72;

const useStyles = makeStyles((theme) => ({
  formContainer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(4),
    boxShadow: theme.shadows[2],
    marginBottom: theme.spacing(4),
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
      marginBottom: theme.spacing(2),
    },
  },
  formContent: {
    position: "relative",
  },
  alertBox: {
    marginTop: theme.spacing(2),
  },
  navigationButtonsTop: {
    position: "absolute",
    top: theme.spacing(2),
    left: theme.spacing(2),
    right: theme.spacing(2),
    display: "flex",
    justifyContent: "space-between",
    zIndex: 1,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  stepper: {
    padding: theme.spacing(2, 0),
    backgroundColor: "transparent",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1, 0),
    },
  },
  generateButtonContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  generatedContentContainer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
    boxShadow: theme.shadows[2],
    marginBottom: theme.spacing(4),
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(2),
    },
    transition: "margin-top 0.3s ease",
  },
  materialContainer: {
    marginBottom: theme.spacing(4),
  },
  generationsButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(1),
    minWidth: "70px",
    minHeight: "40px",
  },
  generationsIcon: {
    fontSize: "1.5rem",
    margin: theme.spacing(0, 4),
  },
  tabContainer: {
    transition: "all 0.3s ease",
    backgroundColor: theme.palette.background.paper,
    zIndex: 1000,
  },
  tabContainerSticky: {
    position: "sticky",
    top: theme.spacing(12.4),
    left: 0,
    right: 0,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[4],
    padding: theme.spacing(0.5, 0),
    transition: "all 0.3s ease",
    height: STICKY_TAB_HEIGHT,
    [theme.breakpoints.down(428)]: {
      top: theme.spacing(10.2),
      height: STICKY_TAB_HEIGHT_MOBILE,
    },
  },
  tabContainerNormal: {
    position: "relative",
    height: "auto",
  },
  tab: {
    fontSize: "1.2rem",
    "&$selected": {
      color:
        theme.palette.type === "dark" ? "#FFFFFF" : theme.palette.primary.main,
    },
    "@media (max-width:428px)": {
      fontSize: "1.0rem",
    },
    cursor: "pointer",
  },
  selected: {},
  snackbar: {
    bottom: theme.spacing(2),
  },
  countsDisplay: {
    marginBottom: theme.spacing(2),
  },
}));

const formSteps = [
  { title: "Personal Information", component: PersonalInformation },
  { title: "Education History", component: Education },
  { title: "Work Experience", component: WorkExperience },
  { title: "Additional Qualifications", component: AdditionalQualifications },
  { title: "Target Job Information", component: TargetJobInformation },
];

function JobDetailsForm({
  generations,
  onAddGenerations,
  onUpdateGenerationContent,
  onContentGenerated,
  selectedGeneration,
  selectedGenerationId,
  onSelectGeneration,
  handleDrawerToggle,
  selectedApplicationMaterial,
  onSelectApplicationMaterial,
  activeTab,
  setActiveTab,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const auth = useAuth();
  const { formData } = useContext(FormContext);

  const [formAlert, setFormAlert] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const totalSteps = formSteps.length;
  const [isLoading, setIsLoading] = useState(false);
  const contentRef = useRef(null);
  const [alertMessage, setAlertMessage] = useState(null);
  const [userCredits, setUserCredits] = useState(0);

  const hasPastGenerations =
    Array.isArray(generations) && generations.length > 0;
  const isSticky = useSticky(SCROLL_THRESHOLD);

  const handleBack = useCallback(() => {
    if (currentStep > 0) {
      setCurrentStep((prev) => prev - 1);
    }
  }, [currentStep]);

  const handleNext = useCallback(async () => {
    if (currentStep === totalSteps - 1) {
      if (hasPastGenerations) {
        handleDrawerToggle();
      }
    } else {
      if (currentStep === 0) {
        try {
          const localData = localStorage.getItem("personalInformation");
          const currentData = formData.personalInformation;
          let dataChanged = true;
          if (localData) {
            const parsedLocalData = JSON.parse(localData);
            dataChanged =
              JSON.stringify(parsedLocalData) !== JSON.stringify(currentData);
          }

          if (dataChanged && auth.user) {
            const userDocRef = doc(db, "users", auth.user.uid);
            await setDoc(
              userDocRef,
              { personalInformation: currentData },
              { merge: true }
            );
            setSnackbarOpen(true);
          }
        } catch (error) {
          console.error(
            "Error saving Personal Information to Firestore:",
            error
          );
          setFormAlert({
            type: "error",
            message: "Error saving data to Firestore.",
          });
        }
      }

      if (currentStep < totalSteps - 1) {
        setCurrentStep((prev) => prev + 1);
      }
    }
  }, [
    currentStep,
    totalSteps,
    hasPastGenerations,
    handleDrawerToggle,
    formData.personalInformation,
    auth.user,
  ]);

  const handleTabChange = useCallback(
    (event, newValue) => {
      setActiveTab(newValue);
      if (newValue === 1 && !selectedGenerationId && hasPastGenerations) {
        const mostRecentGeneration = generations[generations.length - 1];
        onSelectGeneration(mostRecentGeneration.id);
      }
    },
    [
      setActiveTab,
      onSelectGeneration,
      selectedGenerationId,
      generations,
      hasPastGenerations,
    ]
  );

  const renderSection = useCallback(() => {
    const StepComponent = formSteps[currentStep].component;
    return (
      <ErrorBoundary>
        <StepComponent />
      </ErrorBoundary>
    );
  }, [currentStep]);

  const handleGenerate = useCallback(async () => {
    try {
      await formSchema.validate(formData, { abortEarly: false });
      setIsLoading(true);
    } catch (validationErrors) {
      if (validationErrors.inner) {
        console.error("Validation errors:", validationErrors.inner);
      }
      setAlertMessage("Please fix the validation errors before proceeding.");
    }
  }, [formData]);

  const progress = ((currentStep + 1) / totalSteps) * 100;

  useEffect(() => {
    let unsubscribe;

    const listenToUserCredits = () => {
      if (auth.user) {
        const userDocRef = doc(db, "users", auth.user.uid);
        unsubscribe = onSnapshot(userDocRef, (docSnapshot) => {
          if (docSnapshot.exists()) {
            const data = docSnapshot.data();
            setUserCredits(Number(data.credits) || 0);
          } else {
            setUserCredits(5);
          }
        });
      }
    };

    listenToUserCredits();

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [auth.user]);

  const handleContentGeneratedInternal = useCallback(
    (allResults) => {
      setIsLoading(false);
      setActiveTab(1);

      const targetJobs = formData.targetJobInformation || [];

      const newGenerations = allResults.map((res, index) => {
        const job = targetJobs[index] || {};
        const companyName = job.companyName || "Unknown Company";
        const jobTitle = job.jobTitle || "Unknown Job";
        const generationId = `${Date.now()}-${index}`;

        return {
          id: generationId,
          companyName,
          jobTitle,
          ...res,
          jobDetails: job,
        };
      });

      onAddGenerations(newGenerations);
      if (newGenerations.length > 0) {
        onSelectGeneration(newGenerations[0].id);
      }

      setTimeout(() => {
        if (contentRef.current) {
          contentRef.current.scrollIntoView({ behavior: "smooth" });
        }
      }, 100);
    },
    [
      setActiveTab,
      formData.targetJobInformation,
      onAddGenerations,
      onSelectGeneration,
    ]
  );

  // **New Function to Handle Step Clicks**
  const handleStepClick = (step) => () => {
    setCurrentStep(step);
  };

  return (
    <Box>
      {/* 
        NOTE: We removed the ParseDocumentButton from here so that 
        it can be placed externally in ProPage.jsx as requested.
      */}

      <Box className={classes.countsDisplay}>
        <GenerationCreditsDisplay userCredits={userCredits} />
      </Box>

      <Box
        className={`${classes.tabContainer} ${
          isSticky ? classes.tabContainerSticky : classes.tabContainerNormal
        }`}
      >
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="Application Builder and Generated Content Tabs"
        >
          <Tab
            label="APPLICATION BUILDER"
            classes={{ root: classes.tab, selected: classes.selected }}
            id="tab-application-builder"
            aria-controls="tabpanel-application-builder"
          />
          <Tab
            label="Generated Content"
            disabled={!hasPastGenerations}
            classes={{ root: classes.tab, selected: classes.selected }}
            id="tab-generated-content"
            aria-controls="tabpanel-generated-content"
          />
        </Tabs>
      </Box>

      {activeTab === 0 && (
        <Box className={classes.formContainer}>
          {!isMobile && (
            <Stepper
              activeStep={currentStep}
              alternativeLabel
              className={classes.stepper}
              nonLinear // Make Stepper non-linear to allow clicking on steps
            >
              {formSteps.map((step, index) => (
                <Step key={index}>
                  <StepLabel
                    onClick={handleStepClick(index)} // Attach click handler
                    style={{ cursor: "pointer" }} // Change cursor to pointer
                  >
                    {step.title}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          )}

          {isMobile && (
            <FormSectionHeader
              title={formSteps[currentStep].title}
              progress={progress}
              isMobile={isMobile}
              onBack={handleBack}
              onNext={handleNext}
              isLastStep={currentStep === totalSteps - 1}
            />
          )}

          <Box className={classes.formContent}>
            {!isMobile && (
              <Box className={classes.navigationButtonsTop}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleBack}
                  disabled={currentStep === 0}
                  aria-label="Back"
                >
                  Back
                </Button>
                <Tooltip
                  title={
                    currentStep === totalSteps - 1 && hasPastGenerations
                      ? "View Past Application Materials"
                      : "Proceed to Next Step"
                  }
                  arrow
                >
                  <span>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                      disabled={
                        currentStep === totalSteps - 1 && !hasPastGenerations
                      }
                      className={
                        currentStep === totalSteps - 1 && hasPastGenerations
                          ? classes.generationsButton
                          : ""
                      }
                      aria-label={
                        currentStep === totalSteps - 1 && hasPastGenerations
                          ? "View Past Application Materials"
                          : "Next"
                      }
                    >
                      {currentStep === totalSteps - 1 && hasPastGenerations ? (
                        <SidebarToggleIcon
                          className={classes.generationsIcon}
                          aria-hidden="true"
                        />
                      ) : (
                        "Next"
                      )}
                    </Button>
                  </span>
                </Tooltip>
              </Box>
            )}

            {renderSection()}

            {formAlert && (
              <Box className={classes.alertBox}>
                <Alert
                  severity={formAlert.type}
                  onClose={() => setFormAlert(null)}
                  aria-live="assertive"
                >
                  {formAlert.message}
                </Alert>
              </Box>
            )}

            {currentStep === totalSteps - 1 && (
              <Box className={classes.generateButtonContainer}>
                <GenerateApplicationMaterialsButton
                  onContentGenerated={handleContentGeneratedInternal}
                  onGenerateStart={handleGenerate}
                  isLoading={isLoading}
                />
              </Box>
            )}
          </Box>
        </Box>
      )}

      {activeTab === 1 && (
        <Box
          className={classes.generatedContentContainer}
          ref={contentRef}
          style={{ marginTop: isSticky ? STICKY_TAB_HEIGHT : 0 }}
          role="tabpanel"
          id="tabpanel-generated-content"
          aria-labelledby="tab-generated-content"
        >
          {selectedGeneration ? (
            <Box className={classes.materialContainer}>
              {typeof selectedGeneration === "object" ? (
                <GeneratedContentDisplay
                  isVisible
                  // Pass entire generation doc. Old docs might have a `content` field.
                  generatedContent={selectedGeneration}
                  jobDetails={{
                    jobTitle: selectedGeneration.jobTitle,
                    companyName: selectedGeneration.companyName,
                  }}
                  updateGeneratedContent={(key, newValue) => {
                    const updatedContent = {
                      ...selectedGeneration,
                      [key]: newValue,
                    };
                    onUpdateGenerationContent(
                      selectedGeneration.id,
                      updatedContent
                    );
                  }}
                  isLoading={isLoading}
                  generationOptions={
                    selectedGeneration.jobDetails &&
                    selectedGeneration.jobDetails.generationOptions
                  }
                  selectedApplicationMaterial={selectedApplicationMaterial}
                  onSelectApplicationMaterial={onSelectApplicationMaterial}
                />
              ) : (
                <Typography variant="body1" color="error">
                  Generated content is not an object. Check logs and code!
                </Typography>
              )}
            </Box>
          ) : hasPastGenerations ? (
            <Typography variant="body1">
              No generation selected. Please select one from the sidebar.
            </Typography>
          ) : (
            <Typography variant="body1">
              You have no generated content. Please generate content first.
            </Typography>
          )}
        </Box>
      )}

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={!!alertMessage}
        autoHideDuration={6000}
        onClose={() => setAlertMessage(null)}
      >
        <Alert
          onClose={() => setAlertMessage(null)}
          severity="warning"
          variant="filled"
          aria-live="assertive"
        >
          {alertMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}

JobDetailsForm.propTypes = {
  generations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      companyName: PropTypes.string.isRequired,
      jobTitle: PropTypes.string.isRequired,
      jobDetails: PropTypes.object.isRequired,
    })
  ),
  onAddGenerations: PropTypes.func.isRequired,
  onUpdateGenerationContent: PropTypes.func.isRequired,
  onContentGenerated: PropTypes.func.isRequired,
  selectedGeneration: PropTypes.shape({
    id: PropTypes.string.isRequired,
    companyName: PropTypes.string.isRequired,
    jobTitle: PropTypes.string.isRequired,
    jobDetails: PropTypes.object.isRequired,
  }),
  selectedGenerationId: PropTypes.string,
  onSelectGeneration: PropTypes.func.isRequired,
  handleDrawerToggle: PropTypes.func.isRequired,
  selectedApplicationMaterial: PropTypes.string,
  onSelectApplicationMaterial: PropTypes.func.isRequired,
  activeTab: PropTypes.number.isRequired,
  setActiveTab: PropTypes.func.isRequired,
};

JobDetailsForm.defaultProps = {
  generations: [],
  selectedGeneration: null,
  selectedGenerationId: null,
  selectedApplicationMaterial: "",
};

export default JobDetailsForm;
