// src/components/FormComponents.js
import React, { useState } from "react";
import {
  Typography,
  TextField,
  Button,
  InputAdornment,
  Avatar,
  Grid,
  IconButton,
  MenuItem,
  Popper,
  Paper,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  sectionHeaderContainer: {
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(2, 0),
    width: "100%",
    boxSizing: "border-box",
    marginBottom: 0,
  },
  sectionHeaderText: {
    color: theme.palette.common.white,
    fontWeight: 600,
    textAlign: "center",
  },
  subsectionHeader: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  photoUpload: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: theme.spacing(3),
    position: "relative",
  },
  largeAvatar: {
    width: 200,
    height: 200,
    cursor: "pointer",
    "&:hover": {
      opacity: 0.8,
    },
  },
  photoPlaceholder: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
    backgroundColor: theme.palette.grey[300],
    color: theme.palette.text.secondary,
  },
  photoActions: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    gap: theme.spacing(1),
    opacity: 0,
    transition: "opacity 0.3s",
    "$photoUpload:hover &": {
      opacity: 1,
    },
  },
  editButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  deleteButton: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: theme.palette.error.dark,
    },
  },
  photoCaption: {
    marginTop: theme.spacing(1),
    color: theme.palette.text.secondary,
    fontSize: "0.75rem",
  },
  saveButton: {
    marginTop: theme.spacing(2),
  },
  helperTextPopper: {
    zIndex: 1500,
    maxWidth: 250,
  },
  helperTextPaper: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[2],
  },
}));

export const FormField = ({
  name,
  label,
  required = false,
  multiline = false,
  minRows,
  maxRows,
  type = "text",
  value,
  onChange,
  error,
  fullWidth = false,
  tooltip,
  maxLength,
  inputProps = {},
  onKeyDown,
  icon: Icon,
  select = false,
  autocomplete = false,
  options = [],
  InputLabelProps = {},
  className,
  ...props
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();

  const handlePopperOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopperClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const renderInput = (params) => (
    <TextField
      {...params}
      label={label}
      required={required}
      error={!!error}
      helperText={error}
      fullWidth
      variant="outlined"
      InputProps={{
        ...params.InputProps,
        startAdornment: Icon ? (
          <InputAdornment position="start">
            {React.isValidElement(Icon) ? Icon : <Icon />}
          </InputAdornment>
        ) : null,
        endAdornment: (
          <>
            {params.InputProps.endAdornment}
            {tooltip && (
              <InputAdornment position="end">
                <IconButton
                  size="small"
                  onMouseEnter={handlePopperOpen}
                  onMouseLeave={handlePopperClose}
                >
                  <HelpOutlineIcon fontSize="small" />
                </IconButton>
              </InputAdornment>
            )}
          </>
        ),
      }}
      InputLabelProps={{
        ...InputLabelProps,
      }}
      {...props}
    />
  );

  return (
    <Grid item xs={12} sm={fullWidth ? 12 : 6}>
      {autocomplete ? (
        <Autocomplete
          options={options}
          freeSolo
          value={value}
          onChange={(event, newValue) => {
            onChange(name, newValue);
          }}
          renderInput={renderInput}
          {...props}
        />
      ) : (
        <TextField
          name={name}
          label={label}
          required={required}
          multiline={multiline}
          {...(minRows && { minRows })}
          {...(maxRows && { maxRows })}
          type={type}
          fullWidth
          variant="outlined"
          value={value}
          onChange={(e) => {
            onChange(name, e.target.value);
          }}
          error={!!error}
          helperText={error}
          select={select}
          InputProps={{
            startAdornment: Icon ? (
              <InputAdornment position="start">
                {React.isValidElement(Icon) ? Icon : <Icon />}
              </InputAdornment>
            ) : null,
            endAdornment: tooltip && (
              <InputAdornment position="end">
                <IconButton
                  size="small"
                  onMouseEnter={handlePopperOpen}
                  onMouseLeave={handlePopperClose}
                >
                  <HelpOutlineIcon fontSize="small" />
                </IconButton>
              </InputAdornment>
            ),
          }}
          InputLabelProps={{
            ...InputLabelProps,
          }}
          inputProps={{ ...inputProps, maxLength: maxLength }}
          onKeyDown={onKeyDown}
          className={className}
          {...props}
        >
          {select &&
            options.map((option) => (
              <MenuItem
                key={option.value || option}
                value={option.value || option}
              >
                {option.label || option}
              </MenuItem>
            ))}
        </TextField>
      )}
      {tooltip && (
        <Popper
          open={open}
          anchorEl={anchorEl}
          placement="right-start"
          className={classes.helperTextPopper}
        >
          <Paper className={classes.helperTextPaper}>
            <Typography variant="body2">{tooltip}</Typography>
          </Paper>
        </Popper>
      )}
    </Grid>
  );
};

export const SectionHeader = ({ title }) => {
  const classes = useStyles();
  return (
    <div className={classes.sectionHeaderContainer}>
      <Typography variant="h5" className={classes.sectionHeaderText}>
        {title}
      </Typography>
    </div>
  );
};

export const SubsectionHeader = ({ title }) => {
  const classes = useStyles();
  return (
    <Typography variant="h6" className={classes.subsectionHeader}>
      {title}
    </Typography>
  );
};

export const PhotoUpload = ({ onChange, currentPhoto, onDelete }) => {
  const classes = useStyles();

  const handlePhotoUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        onChange(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className={classes.photoUpload}>
      <input
        accept="image/*"
        style={{ display: "none" }}
        id="photo-upload"
        type="file"
        onChange={handlePhotoUpload}
      />
      <label htmlFor="photo-upload">
        <Avatar src={currentPhoto} className={classes.largeAvatar}>
          {!currentPhoto && (
            <div className={classes.photoPlaceholder}>
              <PhotoCameraIcon fontSize="large" />
              <Typography variant="caption">Upload Photo</Typography>
            </div>
          )}
        </Avatar>
      </label>
      {currentPhoto && (
        <div className={classes.photoActions}>
          <label htmlFor="photo-upload">
            <IconButton
              component="span"
              size="small"
              className={classes.editButton}
            >
              <EditIcon />
            </IconButton>
          </label>
          <IconButton
            onClick={onDelete}
            size="small"
            className={classes.deleteButton}
          >
            <DeleteIcon />
          </IconButton>
        </div>
      )}
      <Typography variant="caption" className={classes.photoCaption}>
        Supported formats: JPG, PNG. Max size: 5MB
      </Typography>
    </div>
  );
};

export const DateHelperText = ({ text }) => {
  const useStyles = makeStyles((theme) => ({
    helperText: {
      marginTop: theme.spacing(0.5),
      marginLeft: theme.spacing(1.5),
      fontSize: "0.75rem",
      color: theme.palette.text.secondary,
      fontWeight: 400,
    },
  }));

  const classes = useStyles();

  return (
    <Typography variant="caption" className={classes.helperText}>
      {text}
    </Typography>
  );
};

export const SaveButton = ({ onClick }) => {
  const classes = useStyles();

  return (
    <Button
      variant="contained"
      color="primary"
      className={classes.saveButton}
      onClick={onClick}
    >
      Save and Continue
    </Button>
  );
};

export default {
  FormField,
  SectionHeader,
  SubsectionHeader,
  PhotoUpload,
  SaveButton,
};
