// src/components/FormSection4_AdditionalQualifications.js

import React, {
  useContext,
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react";
import {
  Paper,
  Grid,
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  CardActions,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Tooltip,
  CircularProgress,
  MenuItem,
  Snackbar,
  FormHelperText,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Work as WorkIcon,
  Security as SecurityIcon,
  School as SchoolIcon,
  EmojiObjects as EmojiObjectsIcon,
  Search as SearchIcon,
  Group as GroupIcon,
  MenuBook as MenuBookIcon,
  Create as CreateIcon,
} from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import { FormContext } from "./FormContext";
import { FormField } from "./FormComponents";

// Import the new FormSectionSubHeader component
import FormSectionSubHeader from "./FormSectionSubHeader";

// Firestore imports (Firebase v9 Modular)
import { db } from "../util/firebase";
import { doc, getDoc, setDoc } from "firebase/firestore";

// Auth import
import { useAuth } from "../util/auth";

// Utility imports
import debounce from "lodash.debounce";
import isEqual from "lodash/isEqual";

// Import Yup for validation
import * as Yup from "yup";

const useStyles = makeStyles((theme) => ({
  formPaper: {
    backgroundColor: theme.palette.background.paper,
    overflow: "hidden",
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
  },
  formInnerContent: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  qualificationsHeader: {
    fontWeight: 600,
    marginBottom: theme.spacing(2),
    fontSize: "1.3rem",
    textAlign: "center",
  },
  qualificationCard: {
    display: "flex",
    flexDirection: "column",
    marginBottom: theme.spacing(2),
  },
  qualificationTypeGrid: {
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(2),
  },
  qualificationTypeCard: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  cardActions: {
    marginTop: "auto", // push the button to the bottom
    justifyContent: "center",
  },
  autoSaveAlert: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  qualificationFormContainer: {
    padding: theme.spacing(2),
  },
  formField: {
    marginBottom: theme.spacing(2),
  },
  formButtons: {
    marginTop: theme.spacing(2),
    display: "flex",
    justifyContent: "flex-end",
  },
  dialogTitle: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  addButton: {
    whiteSpace: "normal",
    wordBreak: "break-word",
    textAlign: "center",
    display: "flex", // For vertical centering
    alignItems: "center", // Vertical center
    justifyContent: "center", // Horizontal center
  },
}));

// Static data
// === Note: "Military Service" has been removed from the array below ===
const qualificationTypes = [
  {
    type: "Work Authorization",
    description: "Provide information about your work authorization status.",
    icon: WorkIcon,
  },
  {
    type: "Security Clearance",
    description: "Specify your security clearance level.",
    icon: SecurityIcon,
  },
  {
    type: "Personal Project",
    description:
      "Share information about your personal initiatives or passion projects.",
    icon: CreateIcon,
  },
  {
    type: "Certification",
    description:
      "Add details about your professional licenses, certifications, or other credentials.",
    icon: SchoolIcon,
  },
  {
    type: "Patent",
    description: "Provide information about patents you hold.",
    icon: EmojiObjectsIcon,
  },
  {
    type: "Research Experience",
    description: "Describe your research experience and projects.",
    icon: SearchIcon,
  },
  {
    type: "Volunteering",
    description: "Share details about your community service activities.",
    icon: GroupIcon,
  },
  {
    type: "Professional Development",
    description:
      "Workshops, seminars, courses, or other professional development activities.",
    icon: MenuBookIcon,
  },
];

// === "Military Service" block removed from fieldsByType ===
const fieldsByType = {
  "Work Authorization": [
    {
      name: "status",
      label: "Work Authorization Status",
      required: false,
      select: true,
      options: ["US Citizen", "Permanent Resident", "Work Visa", "Other"],
      icon: WorkIcon,
      tooltip: "Select your current work authorization status",
    },
  ],
  "Security Clearance": [
    {
      name: "level",
      label: "Security Clearance Level",
      required: false,
      select: true,
      options: ["Confidential", "Secret", "Top Secret", "Other"],
      icon: SecurityIcon,
      tooltip: "Select your highest level of security clearance",
    },
  ],
  "Personal Project": [
    {
      name: "projectName",
      label: "Project Name",
      required: true,
      icon: CreateIcon,
      tooltip: "Enter the name of your personal project",
    },
    {
      name: "description",
      label: "Project Description",
      multiline: true,
      minRows: 4,
      required: false,
      icon: CreateIcon,
      tooltip: "Provide a brief description of your project and its goals",
    },
    {
      name: "skills",
      label: "Skills Utilized",
      required: false,
      icon: CreateIcon,
      tooltip: "List the main skills or techniques you used in this project",
    },
    {
      name: "outcome",
      label: "Project Outcome",
      multiline: true,
      minRows: 2,
      icon: CreateIcon,
      tooltip: "Describe the results or impact of your project",
    },
    {
      name: "projectUrl",
      label: "Project URL",
      icon: CreateIcon,
      tooltip: "Enter a URL where your project can be viewed (if applicable)",
    },
  ],
  Certification: [
    {
      name: "credentialType",
      label: "Credential Type",
      required: false,
      select: true,
      options: ["License", "Certification", "Other"],
      icon: SchoolIcon,
      tooltip: "Select the type of professional credential",
    },
    {
      name: "name",
      label: "Credential Name",
      required: false,
      icon: SchoolIcon,
      tooltip: "Enter the name of your professional license or certification",
    },
    {
      name: "issuer",
      label: "Issuing Organization",
      required: false,
      icon: SchoolIcon,
      tooltip: "Enter the name of the organization that issued the credential",
    },
    {
      name: "dateObtained",
      label: "Date Obtained",
      type: "date",
      required: false,
      icon: SchoolIcon,
      tooltip: "Enter the date you obtained this credential",
    },
    {
      name: "expirationDate",
      label: "Expiration Date",
      type: "date",
      icon: SchoolIcon,
      tooltip: "Enter the expiration date of this credential, if applicable",
    },
    {
      name: "description",
      label: "Description",
      multiline: true,
      minRows: 3,
      icon: SchoolIcon,
      tooltip:
        "Provide a brief description of this credential and its relevance",
    },
  ],
  Patent: [
    {
      name: "patentNumber",
      label: "Patent Number",
      required: true,
      icon: EmojiObjectsIcon,
      tooltip: "Enter the patent number",
    },
    {
      name: "patentTitle",
      label: "Patent Title",
      required: true,
      icon: EmojiObjectsIcon,
      tooltip: "Enter the title of the patent",
    },
    {
      name: "dateFiled",
      label: "Date Filed",
      type: "date",
      required: false,
      icon: EmojiObjectsIcon,
      tooltip: "Enter the date the patent was filed",
    },
    {
      name: "dateGranted",
      label: "Date Granted",
      type: "date",
      icon: EmojiObjectsIcon,
      tooltip: "Enter the date the patent was granted",
    },
    {
      name: "description",
      label: "Patent Description",
      multiline: true,
      minRows: 6,
      required: false,
      icon: EmojiObjectsIcon,
      tooltip: "Describe your patent, including its purpose and impact",
    },
  ],
  "Research Experience": [
    {
      name: "projectTitle",
      label: "Project Title",
      required: true,
      icon: SearchIcon,
      tooltip: "Enter the title of the research project",
    },
    {
      name: "role",
      label: "Role",
      required: false,
      icon: SearchIcon,
      tooltip: "Enter your role in the project",
    },
    {
      name: "institution",
      label: "Institution/Organization",
      required: false,
      icon: SearchIcon,
      tooltip: "Enter the name of the institution or organization",
    },
    {
      name: "startDate",
      label: "Start Date",
      type: "date",
      required: false,
      icon: SearchIcon,
      tooltip: "Enter the starting date of the research project",
    },
    {
      name: "endDate",
      label: "End Date",
      type: "date",
      required: false,
      icon: SearchIcon,
      tooltip: "Enter the ending date of the research project",
    },
    {
      name: "description",
      label: "Project Description",
      multiline: true,
      minRows: 4,
      required: false,
      icon: SearchIcon,
      tooltip: "Provide a detailed description of the research project",
    },
    {
      name: "outcomes",
      label: "Outcomes and Findings",
      multiline: true,
      minRows: 3,
      icon: SearchIcon,
      tooltip: "Describe the outcomes or findings of the research project",
    },
  ],
  Volunteering: [
    {
      name: "organization",
      label: "Organization",
      required: true,
      icon: GroupIcon,
      tooltip: "Enter the name of the organization you volunteered with",
    },
    {
      name: "role",
      label: "Role",
      required: false,
      icon: GroupIcon,
      tooltip: "Enter your role or position in the organization",
    },
    {
      name: "startDate",
      label: "Start Date",
      type: "date",
      required: false,
      icon: GroupIcon,
      tooltip: "Enter the date you started volunteering",
    },
    {
      name: "endDate",
      label: "End Date",
      type: "date",
      required: false,
      icon: GroupIcon,
      tooltip: "Enter the date you ended volunteering",
    },
    {
      name: "description",
      label: "Description",
      multiline: true,
      minRows: 4,
      icon: GroupIcon,
      tooltip: "Describe your community service activities and achievements",
    },
  ],
  "Professional Development": [
    {
      name: "name",
      label: "Activity Name",
      required: true,
      icon: MenuBookIcon,
      tooltip: "Enter the name of the professional development activity",
    },
    {
      name: "type",
      label: "Activity Type",
      required: false,
      select: true,
      options: ["Workshop", "Seminar", "Course", "Conference", "Other"],
      icon: MenuBookIcon,
      tooltip: "Select the type of professional development activity",
    },
    {
      name: "date",
      label: "Date",
      type: "date",
      required: false,
      icon: MenuBookIcon,
      tooltip: "Enter the date of the activity",
    },
    {
      name: "description",
      label: "Description",
      multiline: true,
      miminRows: 4,
      icon: MenuBookIcon,
      tooltip: "Provide a brief description of the activity",
    },
    {
      name: "location",
      label: "Location",
      icon: MenuBookIcon,
      tooltip: "Enter the location of the activity",
    },
    {
      name: "organizer",
      label: "Organizer",
      icon: MenuBookIcon,
      tooltip: "Enter the name of the organizing body",
    },
  ],
};

const optionalDate = () =>
  Yup.date().typeError("Invalid date format").nullable(true);

// === "Military Service" has also been removed from validationSchemasByType ===
const validationSchemasByType = {
  "Work Authorization": Yup.object().shape({
    status: Yup.string().nullable(),
  }),
  "Security Clearance": Yup.object().shape({
    level: Yup.string().nullable(),
  }),
  "Personal Project": Yup.object().shape({
    projectName: Yup.string().required("Project Name is required"),
    description: Yup.string().nullable(),
    skills: Yup.string().nullable(),
    outcome: Yup.string().nullable(),
    projectUrl: Yup.string().url("Project URL must be a valid URL").nullable(),
  }),
  Certification: Yup.object().shape({
    credentialType: Yup.string().nullable(),
    name: Yup.string().nullable(),
    issuer: Yup.string().nullable(),
    dateObtained: optionalDate(),
    expirationDate: optionalDate(),
    description: Yup.string().nullable(),
  }),
  Patent: Yup.object().shape({
    patentNumber: Yup.string().required("Patent Number is required"),
    patentTitle: Yup.string().required("Patent Title is required"),
    dateFiled: optionalDate(),
    dateGranted: optionalDate().when("dateFiled", (dateFiled, schema) =>
      dateFiled
        ? schema.min(dateFiled, "Date Granted cannot be before Date Filed")
        : schema
    ),
    description: Yup.string().nullable(),
  }),
  "Research Experience": Yup.object().shape({
    projectTitle: Yup.string().required("Project Title is required"),
    role: Yup.string().nullable(),
    institution: Yup.string().nullable(),
    startDate: optionalDate(),
    endDate: optionalDate().when("startDate", (startDate, schema) =>
      startDate
        ? schema.min(startDate, "End Date cannot be before Start Date")
        : schema
    ),
    description: Yup.string().nullable(),
    outcomes: Yup.string().nullable(),
  }),
  Volunteering: Yup.object().shape({
    organization: Yup.string().required("Organization is required"),
    role: Yup.string().nullable(),
    startDate: optionalDate(),
    endDate: optionalDate().when("startDate", (startDate, schema) =>
      startDate
        ? schema.min(startDate, "End Date cannot be before Start Date")
        : schema
    ),
    description: Yup.string().nullable(),
  }),
  "Professional Development": Yup.object().shape({
    name: Yup.string().required("Activity Name is required"),
    type: Yup.string().nullable(),
    date: optionalDate(),
    description: Yup.string().nullable(),
    location: Yup.string().nullable(),
    organizer: Yup.string().nullable(),
  }),
};

const QualificationForm = ({
  qualificationType,
  initialValues,
  onSave,
  onCancel,
}) => {
  const classes = useStyles();
  const [details, setDetails] = useState(initialValues || {});
  const [errors, setErrors] = useState({});

  const fields = fieldsByType[qualificationType] || [];
  const validationSchema = validationSchemasByType[qualificationType];

  const handleChange = (name, value) => {
    const currentField = fields.find((f) => f.name === name);
    if (currentField && currentField.type === "date" && value === "") {
      value = null;
    }

    setDetails((prev) => ({ ...prev, [name]: value }));
    if (validationSchema) {
      validationSchema
        .validateAt(name, { ...details, [name]: value })
        .then(() => {
          setErrors((prevErrors) => ({ ...prevErrors, [name]: null }));
        })
        .catch((err) => {
          setErrors((prevErrors) => ({ ...prevErrors, [name]: err.message }));
        });
    }
  };

  const handleSave = () => {
    if (validationSchema) {
      validationSchema
        .validate(details, { abortEarly: false })
        .then(() => {
          onSave({ qualificationType, details });
        })
        .catch((err) => {
          let formattedErrors = {};
          if (err.inner && Array.isArray(err.inner) && err.inner.length > 0) {
            formattedErrors = err.inner.reduce((acc, curr) => {
              acc[curr.path] = curr.message;
              return acc;
            }, {});
          } else if (err.path && err.message) {
            formattedErrors[err.path] = err.message;
          }
          setErrors(formattedErrors);
        });
    } else {
      onSave({ qualificationType, details });
    }
  };

  return (
    <Box className={classes.qualificationFormContainer}>
      <Grid container spacing={2}>
        {fields.map((field) => (
          <Grid item xs={12} sm={6} key={field.name}>
            <FormField
              {...field}
              value={details[field.name] || ""}
              onChange={(name, value) => handleChange(name, value)}
              fullWidth
              className={classes.formField}
              error={Boolean(errors[field.name])}
              InputLabelProps={
                field.type === "date" ? { shrink: true } : undefined
              }
            >
              {field.select &&
                field.options.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
            </FormField>
            {errors[field.name] && (
              <FormHelperText error>{errors[field.name]}</FormHelperText>
            )}
          </Grid>
        ))}
      </Grid>
      <Box className={classes.formButtons}>
        <Button onClick={onCancel} style={{ marginRight: 8 }}>
          Cancel
        </Button>
        <Button variant="contained" color="primary" onClick={handleSave}>
          Save
        </Button>
      </Box>
    </Box>
  );
};

const QualificationCard = ({ qualification, onEdit, onDelete }) => {
  const classes = useStyles();
  const { qualificationType, details } = qualification;
  const fields = fieldsByType[qualificationType] || [];

  return (
    <Card className={classes.qualificationCard}>
      <CardContent>
        <Typography variant="h6">{qualificationType}</Typography>
        {details &&
          fields.map((field) => {
            const value = details[field.name];
            if (value) {
              return (
                <Typography key={field.name}>
                  <strong>{field.label}:</strong>{" "}
                  {field.type === "date"
                    ? new Date(value).toLocaleDateString()
                    : value}
                </Typography>
              );
            } else {
              return null;
            }
          })}
      </CardContent>
      <CardActions className={classes.cardActions}>
        <Tooltip title="Edit">
          <IconButton
            onClick={onEdit}
            size="small"
            aria-label={`Edit ${qualificationType}`}
          >
            <EditIcon aria-hidden="true" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton
            onClick={onDelete}
            size="small"
            aria-label={`Delete ${qualificationType}`}
          >
            <DeleteIcon aria-hidden="true" />
          </IconButton>
        </Tooltip>
      </CardActions>
    </Card>
  );
};

export const AdditionalQualifications = () => {
  const classes = useStyles();
  const { formData, updateFormData } = useContext(FormContext);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [editingQualification, setEditingQualification] = useState(null);
  const [autoSaveStatus, setAutoSaveStatus] = useState(null); // 'saving', 'success', 'error'
  const [loading, setLoading] = useState(false);
  const [maxAddButtonHeight, setMaxAddButtonHeight] = useState(null);

  const auth = useAuth();
  const prevFormDataRef = useRef();

  // We'll store a ref to the "Add Professional Development" button and measure its height after render.
  const profDevButtonRef = useRef(null);

  const debouncedAutoSave = useCallback(
    debounce(async (data) => {
      try {
        setAutoSaveStatus("saving");
        await saveData(data);
        setAutoSaveStatus("success");
      } catch (error) {
        console.error("Auto-save failed:", error);
        setAutoSaveStatus("error");
      }
    }, 1000),
    []
  );

  const saveData = async (data) => {
    localStorage.setItem("additionalQualifications", JSON.stringify(data));
    if (auth.user) {
      const userDocRef = doc(db, "users", auth.user.uid);
      try {
        await setDoc(
          userDocRef,
          { additionalQualifications: data },
          { merge: true }
        );
      } catch (error) {
        console.error("Error saving data:", error);
        throw error;
      }
    }
  };

  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      try {
        const localData = localStorage.getItem("additionalQualifications");
        if (localData) {
          const parsedData = JSON.parse(localData);
          updateFormData("additionalQualifications", parsedData);
        } else if (auth.user) {
          const userDocRef = doc(db, "users", auth.user.uid);
          const userDoc = await getDoc(userDocRef);

          if (userDoc.exists()) {
            const data = userDoc.data().additionalQualifications;
            if (data) {
              updateFormData("additionalQualifications", data);
              localStorage.setItem(
                "additionalQualifications",
                JSON.stringify(data)
              );
            }
          }
        }
      } catch (error) {
        console.error("Error loading data:", error);
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, [auth.user, updateFormData]);

  useEffect(() => {
    if (!loading) {
      const currentFormData = formData.additionalQualifications;
      const previousFormData = prevFormDataRef.current;

      if (previousFormData && !isEqual(currentFormData, previousFormData)) {
        debouncedAutoSave(currentFormData);
      }
      prevFormDataRef.current = currentFormData;
    }
  }, [formData.additionalQualifications, debouncedAutoSave, loading]);

  useEffect(() => {
    // After render, measure the height of the "Add Professional Development" button
    // and set all buttons to that height.
    if (profDevButtonRef.current) {
      const height = profDevButtonRef.current.clientHeight;
      if (height && height !== maxAddButtonHeight) {
        setMaxAddButtonHeight(height);
      }
    }
  });

  const handleAddQualification = (qualificationType) => {
    setEditingQualification({ qualificationType, details: {} });
    setDialogOpen(true);
  };

  const handleEditQualification = (qualification, index) => {
    setEditingQualification({ ...qualification, index });
    setDialogOpen(true);
  };

  const handleSaveQualification = (qualification) => {
    if (editingQualification.index !== undefined) {
      const updatedQualifications = [...formData.additionalQualifications];
      updatedQualifications[editingQualification.index] = {
        qualificationType: qualification.qualificationType,
        details: qualification.details,
      };
      updateFormData("additionalQualifications", updatedQualifications);
    } else {
      const updatedQualifications = [
        ...(formData.additionalQualifications || []),
        {
          qualificationType: qualification.qualificationType,
          details: qualification.details,
        },
      ];
      updateFormData("additionalQualifications", updatedQualifications);
    }
    setDialogOpen(false);
    setEditingQualification(null);
  };

  const handleDeleteQualification = (index) => {
    const updatedQualifications = formData.additionalQualifications.filter(
      (_, i) => i !== index
    );
    updateFormData("additionalQualifications", updatedQualifications);
  };

  return (
    <Paper elevation={3} className={classes.formPaper}>
      <FormSectionSubHeader title="Additional Qualifications:" />
      <div className={classes.formInnerContent}>
        {loading ? (
          <Grid container justifyContent="center">
            <CircularProgress />
          </Grid>
        ) : (
          <>
            <Grid
              container
              spacing={3}
              className={classes.qualificationTypeGrid}
              alignItems="stretch"
            >
              {qualificationTypes.map((qualType) => {
                const isProfDev = qualType.type === "Professional Development";
                return (
                  <Grid item xs={12} sm={6} md={4} key={qualType.type}>
                    <Card className={classes.qualificationTypeCard}>
                      <CardContent>
                        <Typography variant="h6" component="div">
                          <span
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {React.createElement(qualType.icon, {
                              style: { marginRight: "8px" },
                              "aria-hidden": "true",
                            })}
                            {qualType.type}
                          </span>
                        </Typography>
                        <Typography variant="body2">
                          {qualType.description}
                        </Typography>
                      </CardContent>
                      <CardActions className={classes.cardActions}>
                        <Button
                          size="small"
                          color="primary"
                          variant="contained"
                          fullWidth
                          className={classes.addButton}
                          onClick={() => handleAddQualification(qualType.type)}
                          startIcon={<AddIcon aria-hidden="true" />}
                          style={{
                            minHeight: maxAddButtonHeight
                              ? maxAddButtonHeight
                              : "auto",
                          }}
                          ref={isProfDev ? profDevButtonRef : null}
                        >
                          Add {qualType.type}
                        </Button>
                      </CardActions>
                    </Card>
                  </Grid>
                );
              })}
            </Grid>
            {(formData.additionalQualifications || []).map(
              (qualification, index) => (
                <QualificationCard
                  key={index}
                  qualification={qualification}
                  onEdit={() => handleEditQualification(qualification, index)}
                  onDelete={() => handleDeleteQualification(index)}
                />
              )
            )}
            <Dialog
              open={dialogOpen}
              onClose={() => setDialogOpen(false)}
              maxWidth="md"
              fullWidth
              aria-labelledby="qualification-dialog-title"
            >
              <DialogTitle
                id="qualification-dialog-title"
                className={classes.dialogTitle}
              >
                {editingQualification?.index !== undefined ? "Edit" : "Add"}{" "}
                {editingQualification?.qualificationType}
              </DialogTitle>
              <DialogContent dividers>
                {editingQualification && (
                  <QualificationForm
                    qualificationType={editingQualification.qualificationType}
                    initialValues={editingQualification.details}
                    onSave={handleSaveQualification}
                    onCancel={() => setDialogOpen(false)}
                  />
                )}
              </DialogContent>
            </Dialog>
          </>
        )}
      </div>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        open={Boolean(autoSaveStatus)}
        autoHideDuration={2000}
        onClose={() => setAutoSaveStatus(null)}
      >
        <Alert
          onClose={() => setAutoSaveStatus(null)}
          severity={
            autoSaveStatus === "error"
              ? "error"
              : autoSaveStatus === "success"
              ? "success"
              : "info"
          }
          variant="filled"
          className={classes.autoSaveAlert}
        >
          {autoSaveStatus === "saving"
            ? "Saving your changes..."
            : autoSaveStatus === "success"
            ? "All changes saved."
            : "All changes saved."}
        </Alert>
      </Snackbar>
    </Paper>
  );
};

export default AdditionalQualifications;
