// src/components/PersonalInformation.js

import React, {
  useContext,
  useState,
  useEffect,
  useCallback,
  memo,
  useRef,
} from "react";
import PropTypes from "prop-types";
import { FormContext } from "./FormContext";
import {
  Grid,
  Paper,
  Typography,
  Snackbar,
  CircularProgress,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import {
  LinkedIn as LinkedInIcon,
  Language as LanguageIcon,
  Twitter as TwitterIcon,
  GitHub as GitHubIcon,
  Person as PersonIcon,
  Home as HomeIcon,
  Phone as PhoneIcon,
  Email as EmailIcon,
  Work as WorkIcon,
} from "@material-ui/icons";
import { FormField } from "./FormComponents";

// Firestore imports (Firebase v9 Modular)
import { db } from "../util/firebase";
import { doc, getDoc, setDoc } from "firebase/firestore";

// Auth import
import { useAuth } from "../util/auth";

// Utility imports
import debounce from "lodash.debounce";
import isEqual from "lodash/isEqual";

// Import Yup validation schema
import { validationSchema } from "./validationSchema";

// Import the new FormSectionSubHeader component
import FormSectionSubHeader from "./FormSectionSubHeader";

/**
 * -------------
 * -- STYLES --
 * -------------
 */
const useStyles = makeStyles((theme) => ({
  formPaper: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
  },
  formContent: {
    display: "flex",
    flexDirection: "column",
  },
  formSection: {
    marginBottom: theme.spacing(2),
  },
  subsectionHeader: {
    color: theme.palette.text.primary,
    fontWeight: 600,
    marginBottom: theme.spacing(1),
  },
  autoSaveAlert: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
}));

/**
 * --------------------------------------------------------
 * -- SubsectionHeader Component (Retained from original) --
 * --------------------------------------------------------
 */
const SubsectionHeader = memo(({ title }) => {
  const classes = useStyles();
  return (
    <Typography variant="h6" className={classes.subsectionHeader}>
      {title}
    </Typography>
  );
});

SubsectionHeader.propTypes = {
  title: PropTypes.string.isRequired,
};

/**
 * ----------------------------------
 * -- FORM FIELDS CONFIGURATION --
 * ----------------------------------
 *
 * By having this structure, it's easier to extend
 * or modify form fields in one place.
 */
const formFields = [
  {
    section: "Name",
    fields: [
      {
        name: "firstName",
        label: "First Name",
        required: true,
        icon: PersonIcon,
      },
      {
        name: "lastName",
        label: "Last Name",
        required: true,
      },
    ],
  },
  {
    section: "Address",
    fields: [
      {
        name: "streetAddress",
        label: "Street Address",
        required: true,
        icon: HomeIcon,
        fullWidth: true,
      },
      { name: "city", label: "City", required: true },
      { name: "state", label: "State/Province", required: true },
      { name: "zipCode", label: "Zip/Postal Code", required: true },
      { name: "country", label: "Country", required: true },
    ],
  },
  {
    section: "Contact Information",
    fields: [
      {
        name: "phone",
        label: "Phone",
        required: true,
        type: "tel",
        icon: PhoneIcon,
      },
      {
        name: "email",
        label: "Email Address",
        required: true,
        type: "email",
        icon: EmailIcon,
      },
    ],
  },
  {
    section: "Online Presence",
    fields: [
      {
        name: "linkedinUrl",
        label: "LinkedIn Profile URL",
        icon: LinkedInIcon,
      },
      {
        name: "websiteUrl",
        label: "Website/Portfolio URL",
        icon: LanguageIcon,
      },
      {
        name: "twitterHandle",
        label: "X (Twitter) Handle",
        icon: TwitterIcon,
      },
      {
        name: "githubUsername",
        label: "GitHub Username",
        icon: GitHubIcon,
      },
      {
        name: "otherSocialProfile",
        label: "Other Social Profile",
        fullWidth: true,
      },
    ],
  },
  {
    section: "Professional Information",
    fields: [
      {
        name: "professionalTitle",
        label: "Professional Title",
        icon: WorkIcon,
        fullWidth: true,
      },
      {
        name: "summary",
        label: "Summary/Objective Statement",
        multiline: true,
        minRows: 4,
        fullWidth: true,
        maxLength: 800,
      },
    ],
  },
];

/**
 * -------------------------------------
 * -- Custom Hook for Debounced Save --
 * -------------------------------------
 * Helps keep the component code cleaner.
 */
function useDebouncedAutoSave(callback, delay) {
  const isMountedRef = useRef(true);

  useEffect(() => {
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  const debouncedFn = useCallback(
    debounce(async (data) => {
      if (!isMountedRef.current) return;
      await callback(data);
    }, delay),
    [callback, delay]
  );

  useEffect(() => {
    // Cleanup on unmount
    return () => {
      debouncedFn.cancel();
    };
  }, [debouncedFn]);

  return debouncedFn;
}

/**
 * -----------------------------------------
 * -- PersonalInformation Component Main --
 * -----------------------------------------
 */
export const PersonalInformation = () => {
  const classes = useStyles();
  const { formData, updateFormData } = useContext(FormContext);
  const [errors, setErrors] = useState({});
  const [autoSaveStatus, setAutoSaveStatus] = useState(null);
  const [loading, setLoading] = useState(true);

  // Auth hook to know if user is logged in
  const auth = useAuth();

  // Track if component is mounted
  const isMountedRef = useRef(true);
  useEffect(() => {
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  // Ref to track previous personal information for change detection
  const prevPersonalInfoRef = useRef(formData.personalInformation);
  // Ref to track last successfully saved data to prevent duplicate saves
  const lastSavedDataRef = useRef(formData.personalInformation);
  // Ref to track the current auto-save call id to avoid race conditions
  const callIdRef = useRef(0);

  // ------------------------------------------------------------------
  // -- FIELD CHANGE HANDLER --
  //    Updates form data and triggers field-level validation
  // ------------------------------------------------------------------
  const handleChange = useCallback(
    (name, value) => {
      const updatedData = {
        ...formData.personalInformation,
        [name]: value || "",
      };

      // Update global form data context
      updateFormData("personalInformation", updatedData);

      // Validate only this field
      validationSchema
        .validateAt(name, updatedData)
        .then(() => {
          if (isMountedRef.current) {
            setErrors((prev) => ({ ...prev, [name]: null }));
          }
        })
        .catch((err) => {
          if (isMountedRef.current) {
            setErrors((prev) => ({ ...prev, [name]: err.message }));
          }
        });
    },
    [formData.personalInformation, updateFormData]
  );

  // ------------------------------------------------------------------
  // -- SAVE DATA FUNCTION --
  //    Saves to localStorage and Firestore if a user is logged in
  // ------------------------------------------------------------------
  const saveData = useCallback(
    async (data) => {
      try {
        // Save to localStorage
        localStorage.setItem("personalInformation", JSON.stringify(data));

        // Save to Firestore if user is authenticated
        if (auth.user) {
          const userDocRef = doc(db, "users", auth.user.uid);
          await setDoc(
            userDocRef,
            { personalInformation: data },
            { merge: true }
          );
        }
      } catch (error) {
        console.error("Error saving data:", error);
        throw error;
      }
    },
    [auth.user]
  );

  // ------------------------------------------------------------------
  // -- Memoized Auto-Save Callback --
  //    Checks if data has changed since last successful save and
  //    uses a callId to ignore outdated results.
  // ------------------------------------------------------------------
  const autoSaveCallback = useCallback(
    async (data) => {
      if (isEqual(data, lastSavedDataRef.current)) {
        return;
      }
      callIdRef.current += 1;
      const currentCallId = callIdRef.current;
      if (isMountedRef.current) {
        setAutoSaveStatus("saving");
      }
      try {
        await saveData(data);
        lastSavedDataRef.current = data;
        if (isMountedRef.current && currentCallId === callIdRef.current) {
          setAutoSaveStatus("success");
        }
      } catch (err) {
        console.error("Auto-save error:", err);
        // Delay updating the error state by 500ms to help prevent flicker
        if (isMountedRef.current && currentCallId === callIdRef.current) {
          setTimeout(() => {
            if (isMountedRef.current && currentCallId === callIdRef.current) {
              setAutoSaveStatus("error");
            }
          }, 500);
        }
      }
    },
    [saveData]
  );

  // ------------------------------------------------------------------
  // -- DEBOUNCED AUTO-SAVE --
  //    We'll rely on the custom hook useDebouncedAutoSave with the memoized callback.
  // ------------------------------------------------------------------
  const debouncedAutoSave = useDebouncedAutoSave(autoSaveCallback, 1000);

  // ------------------------------------------------------------------
  // -- LOAD DATA ON MOUNT --
  //    Attempts to load from localStorage first; if empty and user
  //    is authenticated, loads from Firestore
  // ------------------------------------------------------------------
  useEffect(() => {
    const loadData = async () => {
      try {
        const localData = localStorage.getItem("personalInformation");
        if (localData) {
          const parsedData = JSON.parse(localData);
          if (isMountedRef.current) {
            updateFormData("personalInformation", parsedData);
          }
        } else if (auth.user) {
          const userDocRef = doc(db, "users", auth.user.uid);
          const userDoc = await getDoc(userDocRef);
          if (userDoc.exists() && userDoc.data().personalInformation) {
            const data = userDoc.data().personalInformation;
            if (isMountedRef.current) {
              updateFormData("personalInformation", data);
              localStorage.setItem("personalInformation", JSON.stringify(data));
            }
          }
        }
      } catch (error) {
        console.error("Error loading data:", error);
      } finally {
        if (isMountedRef.current) {
          setLoading(false);
        }
      }
    };

    loadData();
  }, [auth.user, updateFormData]);

  // ------------------------------------------------------------------
  // -- AUTO-SAVE ON CHANGES --
  //    Only trigger autosave if the personalInformation has actually changed.
  //    Cancel any pending debounced call before scheduling a new one.
  // ------------------------------------------------------------------
  useEffect(() => {
    if (!loading) {
      if (!isEqual(formData.personalInformation, prevPersonalInfoRef.current)) {
        debouncedAutoSave.cancel();
        debouncedAutoSave(formData.personalInformation);
        prevPersonalInfoRef.current = formData.personalInformation;
      }
    }
  }, [formData.personalInformation, debouncedAutoSave, loading]);

  // ------------------------------------------------------------------
  // -- RENDER --
  // ------------------------------------------------------------------
  return (
    <Paper elevation={3} className={classes.formPaper}>
      <FormSectionSubHeader title="Personal Information:" />
      <div className={classes.formContent}>
        {loading ? (
          <Grid container justifyContent="center">
            <CircularProgress aria-label="Loading personal information" />
          </Grid>
        ) : (
          <form noValidate autoComplete="off">
            {formFields.map(({ section, fields }) => (
              <React.Fragment key={section}>
                <SubsectionHeader title={section} />
                <Grid container spacing={3} className={classes.formSection}>
                  {fields.map((field) => (
                    <FormField
                      key={field.name}
                      name={field.name}
                      label={field.label}
                      required={field.required}
                      type={field.type}
                      value={formData.personalInformation[field.name] || ""}
                      onChange={handleChange}
                      error={errors[field.name]}
                      tooltip={`Enter your ${field.label.toLowerCase()}`}
                      icon={field.icon ? <field.icon /> : null}
                      fullWidth={field.fullWidth}
                      multiline={field.multiline}
                      minRows={field.minRows}
                      rows={undefined}
                      maxLength={field.maxLength}
                      aria-label={field.label}
                    />
                  ))}
                </Grid>
              </React.Fragment>
            ))}
          </form>
        )}
      </div>
      {/* Auto-Save Snackbar */}
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        open={Boolean(autoSaveStatus)}
        autoHideDuration={2000}
        onClose={() => {
          if (isMountedRef.current) {
            setAutoSaveStatus(null);
          }
        }}
      >
        <Alert
          onClose={() => {
            if (isMountedRef.current) {
              setAutoSaveStatus(null);
            }
          }}
          severity={
            autoSaveStatus === "error"
              ? "error"
              : autoSaveStatus === "success"
              ? "success"
              : "info"
          }
          variant="filled"
          className={classes.autoSaveAlert}
        >
          {autoSaveStatus === "saving"
            ? "Auto-saving..."
            : autoSaveStatus === "success"
            ? "All changes saved"
            : "All changes saved"}
        </Alert>
      </Snackbar>
    </Paper>
  );
};

PersonalInformation.propTypes = {
  // Add any PropTypes if needed
};

export default PersonalInformation;
