// src/components/ParseDocumentButton.jsx

import React, { useState, useRef, useContext, useCallback } from "react";
import {
  Button,
  makeStyles,
  Tooltip,
  useTheme,
  useMediaQuery,
  CircularProgress,
  Snackbar,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { FormContext } from "./FormContext";

// Define allowed file types and max size
const ALLOWED_FILE_TYPES = [
  ".pdf",
  ".doc",
  ".docx",
  ".jpg",
  ".jpeg",
  ".png",
  ".heic",
];
const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB

// Cloud Function URL
const CLOUD_FUNCTION_URL =
  // "https://us-central1-resumerevivalapp.cloudfunctions.net/prod_o3_mini_parse_uploaded_resume";
  // "https://us-central1-resumerevivalapp.cloudfunctions.net/dev_o3_mini_parse_uploaded_resume";
  // "https://us-central1-resumerevivalapp.cloudfunctions.net/dev_o3_mini_parse_uploaded_resume-1";
  // "https://us-central1-resumerevivalapp.cloudfunctions.net/dev_o3_mini_parse_uploaded_resume";
  "https://us-central1-resumerevivalapp.cloudfunctions.net/prod_parse_uploaded_documents";

const useStyles = makeStyles((theme) => ({
  uploadButton: {
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(2),
    backgroundColor: theme.palette.success.main,
    color: theme.palette.common.white,
    textTransform: "none",
    fontWeight: 600,
    padding: theme.spacing(1.5, 3),
    display: "flex",
    alignItems: "center",
    borderRadius: theme.shape.borderRadius * 2,
    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
    "&:hover": {
      backgroundColor: theme.palette.success.dark,
    },
    // Styles for extra-small screens
    [theme.breakpoints.down("xs")]: {
      fontSize: "1rem",
      padding: theme.spacing(2, 3),
    },
  },
  fileInput: {
    display: "none",
  },
  icon: {
    marginRight: theme.spacing(1),
    fontSize: "1.4rem",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.2rem",
    },
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

/**
 * Helper function to update localStorage while preserving specific keys.
 * @param {Object} data - The data to store in localStorage.
 * @param {Array<string>} excludeKeys - Keys to exclude from being overwritten.
 */
const updateLocalStorage = (data, excludeKeys = []) => {
  const keysToUpdate = Object.keys(data).filter(
    (key) => !excludeKeys.includes(key)
  );

  keysToUpdate.forEach((key) => {
    try {
      localStorage.setItem(key, JSON.stringify(data[key]));
    } catch (error) {
      console.error(`Failed to store ${key} in localStorage:`, error);
    }
  });
};

export default function ParseDocumentButton() {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const fileInputRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const { populateFormData } = useContext(FormContext);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  /**
   * Handle the upload button click by triggering the hidden file input.
   */
  const handleUploadClick = useCallback(() => {
    fileInputRef.current?.click();
  }, []);

  /**
   * Validate the selected file type.
   * @param {File} file - The file to validate.
   * @returns {boolean} - Whether the file type is allowed.
   */
  const isValidFileType = (file) => {
    const fileName = file.name.toLowerCase();
    return ALLOWED_FILE_TYPES.some((type) => fileName.endsWith(type));
  };

  /**
   * Handle file selection and initiate the parsing process.
   * @param {Event} event - The file input change event.
   */
  const handleFileChange = async (event) => {
    const files = event.target.files;
    if (!files || files.length === 0) return;

    // Validate each file
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (!isValidFileType(file)) {
        setSnackbar({
          open: true,
          message: `Invalid file type. Please upload one of the following: ${ALLOWED_FILE_TYPES.join(
            ", "
          )}`,
          severity: "error",
        });
        resetFileInput();
        return;
      }
      if (file.size > MAX_FILE_SIZE) {
        setSnackbar({
          open: true,
          message:
            "File size exceeds the 5MB limit. Please upload a smaller file.",
          severity: "error",
        });
        resetFileInput();
        return;
      }
    }

    setLoading(true);

    try {
      const formData = new FormData();
      // Append all files
      for (let i = 0; i < files.length; i++) {
        formData.append("file", files[i]);
      }

      const response = await fetch(CLOUD_FUNCTION_URL, {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        const errorData = await response.json();
        const errorMsg =
          errorData.message || `Error parsing document: ${response.statusText}`;
        throw new Error(errorMsg);
      }

      const resultData = await response.json();

      // Update FormContext
      populateFormData(resultData);

      // Update localStorage while preserving 'targetJobInformation'
      updateLocalStorage(resultData, ["targetJobInformation"]);

      console.log("Documents parsed successfully:", resultData);
      setSnackbar({
        open: true,
        message: "Documents parsed and form autofilled successfully!",
        severity: "success",
      });
    } catch (error) {
      console.error("Error uploading/parsing file:", error);
      setSnackbar({
        open: true,
        message:
          error.message ||
          "There was an error uploading or parsing your documents. Please try again.",
        severity: "error",
      });
    } finally {
      setLoading(false);
      resetFileInput();
      // Return focus to the upload button for accessibility
      document.getElementById("upload-button")?.focus();
    }
  };

  /**
   * Reset the file input value to allow re-uploading the same file if needed.
   */
  const resetFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  /**
   * Handle closing of the Snackbar.
   */
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <>
      <input
        ref={fileInputRef}
        type="file"
        accept={ALLOWED_FILE_TYPES.join(", ")}
        multiple
        className={classes.fileInput}
        onChange={handleFileChange}
        aria-label="Upload documents"
      />
      <Tooltip
        title="Upload one or more professional documents (e.g., resume, cover letter, awards certificate) to autofill your application builder form."
        arrow
      >
        <Button
          id="upload-button"
          variant="contained"
          className={classes.uploadButton}
          onClick={handleUploadClick}
          disabled={loading}
          startIcon={
            loading ? (
              <CircularProgress size={20} color="inherit" />
            ) : (
              <CloudUploadIcon className={classes.icon} />
            )
          }
          aria-label="Upload and parse documents"
        >
          {loading
            ? isMobile
              ? "Parsing..."
              : "Parsing Documents..."
            : isMobile
            ? "Upload & Autofill"
            : "Upload & Autofill from Documents"}
        </Button>
      </Tooltip>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
}
